import React, { useRef, useEffect, useState } from "react"
import { Link } from "gatsby"
import { Parallax } from "react-scroll-parallax"
import * as s from "./industry-boxes.module.css"

const IndustryBoxes = ({ sendata }) => {
  const d = sendata
  const boxRef = useRef()
  const [aniBoxStart, setAniBoxStart] = useState()
  const [aniBoxEnd, setAniBoxEnd] = useState()

  const getPosition = () => {
    setAniBoxStart(boxRef.current.offsetTop - 475)
    setAniBoxEnd(boxRef.current.offsetTop - 150)
  }

  useEffect(() => {
    getPosition()
  }, [])

  const indCards = d.wwdInfoBlocksIndustries || new Array(3).fill(null)

  const cardBlock = indCards.map((aCard, index) => {
    let speed = 5
    let translate = 30

    if (index === 1) {
      translate = 0
    }

    if (index === 2) {
      speed = 2.5
    }

    let linkUrl = aCard.wwdInfoBlockLinkUrlIndustries.url.replace(
      "https://content.waracle.com",
      ""
    )

    // image

    const bgImg = aCard.wwdInfoBlockImageIndustries.mediaDetails.file

    // return industry blocks
    return (
      <div className="col-12 col-lg-4 mb-3" key={index}>
        <Parallax
          speed={speed}
          translateY={[translate, 0]}
          startScroll={aniBoxStart}
          endScroll={aniBoxEnd}
        >
          <div
            className={`bg_image color-white ${s.ani_show_content} text_center`}
            style={{
              backgroundImage: `url(https://res.cloudinary.com/dvmdv4ttu/image/upload/f_auto,c_fill,w_700,h_800,q_70,g_face/img/${bgImg})`,
            }}
          >
            <div
              className={`col-12 p-lg-5 p-4 z_index_top ${s.center_absolute} center_absolute add_transition`}
            >
              <h2>{aCard.wwdInfoBlockTitleIndustries}</h2>
              <div className={s.show_more}>
                <p>{aCard.wwdInfoBlockIntroIndustries}</p>
                <Link
                  to={linkUrl}
                  title={aCard.wwdInfoBlockLinkTextIndustries}
                  className="ani-underline color-white"
                >
                  {aCard.wwdInfoBlockLinkTextIndustries}
                </Link>
              </div>
            </div>
            <div className="dark_image_overly"></div>
          </div>
        </Parallax>
      </div>
    )
  })

  return (
    <>
      <div className="container mb-5" ref={boxRef}>
        <div className="row">
          <div className="col-12 mt-3">
            <h2>{d.wwdIndustriesTitle}</h2>
          </div>
        </div>
      </div>
      <div className="container mb-100">
        <div className="row color-white">{cardBlock}</div>
      </div>
    </>
  )
}

export default IndustryBoxes
